import { Layout } from 'antd';
import BEIAN from '../assets/beian.png';

const {  Footer } = Layout;

const SiteFooter = () => (
    <Footer style={{ textAlign: 'center' }}>
      <div style={{
          textAlign: 'center', 
      }}>
          购分享 ©2023 Created by jecyu
          <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023076548号-1</a>
      </div>
      <div style={{
          width: '300px',
          margin: '0 auto', 
          padding: '20px 0',
      }}>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502010230" style={{display: 'inline-block', textDecoration: 'none', height: '20px', lineHeight: '20px'}}>
              <img src={BEIAN} style={{float: 'left'}}/>
              <p style={{float: 'left', height: '20px', lineHeight: '20px', margin: '0px 0px 0px 5px', color: '#939393'}}>粤公网安备 44030502010230号</p>
          </a>
      </div>
      </Footer>
  );

  export default SiteFooter;