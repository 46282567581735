import React from "react";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import router from "./routes/index";
import "./App.css";
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#FF0080",
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
