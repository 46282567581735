import React from "react";
import { Layout } from "antd";
import SiteFooter from "./Footer";
import { Outlet } from "react-router-dom"; // 渲染子路由位置
import "./Layout.css";

const { Header, Content } = Layout;

const Logo = () => (
  <div className="logo-container" style={{ color: "#fff" }}>
    <img src={require("../assets/logo.png")} />
  </div>
);

const LayoutComponent = () => {
  return (
    <Layout className="layout">
      <Header className="page-header">
        <Logo />
        {/* TODO 口令转换页面，只有特定用户鉴权后才能进入 */}
        {/* <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={new Array(1).fill(null).map((_, index) => {
            const key = index + 1;
            return {
              key,
              label: `nav ${key}`,
            };
          })}
        /> */}
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <Outlet />
      </Content>
      <SiteFooter />
    </Layout>
  );
};

export default LayoutComponent;
