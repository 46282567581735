import BasicLayout from "../layout/Layout";
import { createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
import Container from "./AuthContainer";
const routes = [
  {
    path: "",
    component: BasicLayout,
    name: "index",
    children: [
      {
        path: "/",
        name: "home",
        component: lazy(() => import("../pages/home")),
      },
      {
        path: "/tbpwd",
        name: "tbpwd",
        component: lazy(() => import("../pages/tbpwd")),
      },
    ],
  },
];

const formatRoutes = (routes) => {
  return routes.map((item) => {
    if (item.children) {
      item.children = formatRoutes(item.children);
    }
    if (item.name === "index") {
      item.element = <item.component />;
    } else {
      item.element = <Container Component={item.component} />;
    }
    return item;
  });
};

console.log(formatRoutes(routes));

const router = createBrowserRouter(formatRoutes(routes));

export default router;
